export const getCountryList=(countries)=>{
    let newArray=[]
    try {
        newArray = countries.map((item) => {
          return {
            value: item.countryId,
            label: item.Title,
            icon: item.flagurl
          }
        })
      } catch (e) {
        console.log(e)
      }

    return newArray
}

export const getCurrenciesList=(currencies)=>{
    let newArray=[]
    try {
        newArray = currencies.map((item) => {
          return {
            value: item.CurrencyId,
            label: (
              item.Abbreviation
            ),
          }
        })
      } catch (e) {
        console.log(e)
      }

    return newArray
}

export const getTotalCurrencies=(currencies)=>{
    let newArray=[]
    try {
        newArray = currencies.map((item) => {
          return {
            value: item.Id,
            label: (item.Abbreviation),
          }
        })
      } catch (e) {
        console.log(e)
      }

    return newArray
}

export const getLanguagesList=(countries)=>{
    let newArray=[]
    try {
        newArray = countries.map((item) => {
          return {
            value: item.lngId,
            label: (
              item.nativeName
            ),
          }
        })
      } catch (e) {
        console.log(e)
      }

    return newArray
}


export const getDocsTypeList=(docsType)=>{
    let newArray=[]
    try {
        newArray = docsType.map((item) => {
          return {
            value: item.Id,
            label: (
              item.Title
            ),
          }
        })
      } catch (e) {
        console.log(e)
      }

    return newArray
}

export const getFieldTypesList=(fieldTypes)=>{
    let newArray=[]
    try {
        newArray = fieldTypes.map((item) => {
          return {
            value: item.Id,
            label: (
              item.Name
            ),
          }
        })
      } catch (e) {
        console.log(e)
      }

    return newArray
}

export const getPaymentTypesSelectList=(types)=>{
    let newArray=[]
    try {
        newArray = types.map((item) => {
          return {
            value: item.id,
            label: (
              item.Title
            ),
          }
        })
      } catch (e) {
        console.log(e)
      }

    return newArray
}

export const getPaymentMethodsSelectList = (types) => {
    let newArray = []
    try {
        newArray = types.map((item) => {
          return {
            value: item?.PaymentTypeId,
            label: item?.PaymentTypeTitle,
            icon: item?.ImageUrl
          }
        })
      } catch (e) {
        console.log(e)
      }

    return newArray
}

export const getUsersList=(users)=>{
  let newArray=[]
  try {
      newArray = users.map((item) => {
        return {
          value: item.Id,
          label: (
            `${item.FirstName} ${item.LastName}`
          ),
        }
      })
    } catch (e) {
      console.log(e)
    }

  return newArray
}

export const getReceiversList = (users,t) => {
  let newArray=[]
  try {
      newArray = users.map((item) => {
        return {
          value: item.AccountNumber,
          label: ( item.FirstName + ' ' + item.LastName + ' / ' + item.BankName + ' / ' + item.AccountNumber           
          ),
          icon: item.ImgUrl
        }
      })
    } catch (e) {
      console.log(e)
    }

  return newArray
}

export const getBankList = (banks) => {
  let newArray = []
  try {
      newArray = banks.map((item) => {
        return {
          value: item.Id,
          label: item.Title,
          icon: item.BankLogoUrl
        }
      })
    } catch (e) {
      console.log(e)
    }

  return newArray
}

export const getRolesList = (roles) => {
  let newArray = []
  try {
      newArray = roles.map((item) => {
        return {
          value: item.Id,
          label: item.Title,
        }
      })
    } catch (e) {
      console.log(e)
    }

  return newArray
}

export const getDocsFields = (fields) => {
  let newArray = []
  try {
      newArray = fields.map((item) => {
        return {
          value: item.Id,
          label: item.Title,
        }
      })
    } catch (e) {
      console.log(e)
    }

  return newArray
}

