import axios from 'axios'
import {
    getDocsTypeList,
    getFieldTypesList
} from '../../helpers/InputSelectHelper'
import Storages from '../../helpers/Storages'

import {
    GET_COUNTRIES_LIST,
    COUNTRIES_DOCS,
    SET_COUNTRIE_ID,
    DOCS_TYPE,
    DOC_FIELD,
    FIELD_TYPES,
    WAITITNG,
    COUNTRIES_DOCS_LOADING,
    DOCS_FIELDS_LOADING,
    DOC_FIELD_DETAILS_LOADING,
    DOC_FIELD_DETAILS,
    TOGGLE_COUNTRY_DOCS_LANG_MODAL,
} from '../actionTypes'

import {
    getLanguages
} from '../index'

export const getCoutriesList = (list) => ({
    type: GET_COUNTRIES_LIST,
    payload: list
})

export const setCountriesDocs = (list) => ({
    type: COUNTRIES_DOCS,
    payload: list
})

export const setCountryId = (id) => ({
    type: SET_COUNTRIE_ID,
    payload: id
})

export const getCountriesDocs = (endpoints, id) => {
    return (dispatch) => {
        dispatch(setCountriesDocsLoading(true))
        axios
            .get(endpoints.countriesDocs, {
                params: {
                    "countryId": id
                },
                headers: {
                    'x-auth-token': Storages.getUserToken()
                },
            })
            .then((res) => {
                try {
                    if (res.data.status) {
                        dispatch(setCountriesDocs(res.data.result))
                        // dispatch(getDocsType(endpoints))
                        dispatch(setCountryId(id))
                        dispatch(setCountriesDocsLoading(false))
                        dispatch(getLanguages(endpoints, id))
                        return
                    }
                } catch (e) {
                    console.log(e)
                }
            })
            .catch((e) => {
                console.log(e)
                dispatch(setWaiting(false))
            })
    }
}

export const updateCountriesDocs = (endpoints, data) => {
    return (dispatch) => {
        return axios
            .put(endpoints.countriesDocs, data, {
                headers: {
                    'x-auth-token': Storages.getUserToken()
                },
            })
            .then((res) => {
                try {
                    if (res.data.status) {
                        // if (res.data.result.checked == 1)
                            return res.data.result
                        // else if (res.data.checked == 0) return false
                    }
                } catch (e) {
                    console.log(e)
                }
            })
            .catch((e) => {
                console.log(e)
                dispatch(setWaiting(false))
            })
    }
}

export const getDocFields = (endpoints, id) => {
    return (dispatch) => {
        dispatch(setDocsFieldLoading(true))
        axios
            .get(endpoints.docFields, {
                params: {
                    "IdentityVerificationTypeId": id
                },
                headers: {
                    'x-auth-token': Storages.getUserToken()
                },
            })
            .then((res) => {
                try {
                    if (res.data.status) {
                        dispatch(setDocFields(res.data.result))
                        dispatch(setDocsFieldLoading(false))
                        return
                    }
                } catch (e) {
                    console.log(e)
                }
            })
            .catch((e) => {
                console.log(e)
                dispatch(setWaiting(false))
            })
    }
}

export const getDocsType = (endpoints) => {
    return (dispatch) => {
        dispatch(setWaiting(true))
        axios
            .get(endpoints.docType, {
                headers: {
                    'x-auth-token': Storages.getUserToken()
                },
            })
            .then((res) => {
                dispatch(setWaiting(false))
                if (res.data.status) {
                    let docsType = getDocsTypeList(res.data.result)
                    dispatch(setDocsType(docsType))
                    return
                }
            })
            .catch((e) => {
                console.log(e)
                dispatch(setWaiting(false))
            })
    }
}

export const setDocsType = (list) => ({
    type: DOCS_TYPE,
    payload: list
})

export const setDocFields = (list) => ({
    type: DOC_FIELD,
    payload: list
})

export const setWaiting = (status) => ({
    type: WAITITNG,
    payload: status
})

export const setCountriesDocsLoading = (status) => ({
    type: COUNTRIES_DOCS_LOADING,
    payload: status
})

export const setDocsFieldLoading = (status) => ({
    type: DOCS_FIELDS_LOADING,
    payload: status
})

export const getFieldTypes = (endpoints) => {
    return (dispatch) => {
        axios
            .get(endpoints.fieldTypes, {
                headers: {
                    'x-auth-token': Storages.getUserToken()
                },
            })
            .then((res) => {
                let newArray = getFieldTypesList(res.data.result)
                dispatch(setFieldTypes(newArray))
            })
            .catch((e) => console.log(e))
    }
}

export const setFieldTypes = (list) => ({
    type: FIELD_TYPES,
    payload: list
})

export const getDocFieldsDetails = (endpoints, id) => {
    return (dispatch) => {
        dispatch(toggleDocFieldsDetailsLoading(true))
        axios
            .get(endpoints.docFieldLang, {
                params: {
                    "Id": id
                },
                headers: {
                    'x-auth-token': Storages.getUserToken()
                },
            })
            .then((res) => {
                console.log(res.data)
                // let newArray = getFieldTypesList(res.data.result)
                dispatch(toggleDocFieldsDetailsLoading(false))
                dispatch(setDocFieldsDetails(res.data.result))
            })
            .catch((e) => console.log(e))
    }
}

export const setDocFieldsDetails = (list) => ({
    type: DOC_FIELD_DETAILS,
    payload: list
})

export const toggleDocFieldsDetailsLoading = (status) => ({
    type: DOC_FIELD_DETAILS_LOADING,
    payload: status
})

export const toggleCountryDocsLangModal = (status) => ({
    type: TOGGLE_COUNTRY_DOCS_LANG_MODAL,
    payload: status
})