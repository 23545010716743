import {
    TOGGLE_THEME,
    TOGGLE_DRAWER,
    SMALL_SCREEN,
    SET_ACCESS_LIST,
    LOCAL_CHANGE,
    COUTRIES_INFO,
    SET_HOME_PAGE_DATE,
    GET_TOTAL_COUNTRUIES,
    ERROR_NOTIFICATION,
    GET_LANGUAGES_LIST,
    SET_TOTAL_CURRENCIES,
    TOGGLE_SOCKET,
    SET_NOTES,
    SET_PAY_REQUESTS_COUNT,
    SET_PAGES,
} from '../actionTypes'
import Storages from '../../helpers/Storages'
import { getCountryList , getCurrenciesList } from '../../helpers/InputSelectHelper'

import en from '../../json/locale/en.json'
import fa from '../../json/locale/fa.json'

const initState = {
    local: 'en',
    isDark: false,
    isSmallScreen: false,
    drawerMode: false,
    socketConnected: false,
    currentPaymentRequestsData:{
        count: 0
    },
    translate: en,
    notes: [],
    countries: [],
    countriesSelect: [],
    totalCountries: [],
    totalCountriesSelect: [],
    currencies: [],
    totalCurrenciesSelect: [],
    currenciesSelect: [],
    homePageDate: [],
    languages: [],
    axiosErrors: [],
    accessList: [],
    pages: [],
}

export const appReducer = (state = initState, action) => {
    switch (action.type) {
        case TOGGLE_THEME:
            return {
                ...state,
                isDark:!state.isDark
            }
        case TOGGLE_DRAWER:
            return {
                ...state,
                drawerMode:!state.drawerMode
            }
        case SMALL_SCREEN:
            return {
                ...state,
                isSmallScreen: action.payload
            }
        case SET_ACCESS_LIST:
            return {
                ...state,
                accessList: action.payload
            }
        case LOCAL_CHANGE:
            document.body.dir = action.payload === 'fa' ? 'rtl' : 'ltr'
            document.body.lang = action.payload
            return {
                ...state,
                local: action.payload,
                    translate: action.payload === 'fa' ? fa : en,
            }
        case COUTRIES_INFO:
            document.body.dir = Storages.getDir() || 'ltr'
            let countryArray
            let currenciesArray
            try {
                // console.log(action.payload[0].DefLngId)
                if (Storages.getLang() === null)
                    Storages.setLang(action.payload[0].iso639_1)
                Storages.setLangId(action.payload[0].DefLngId)
                countryArray = getCountryList(action.payload)
                currenciesArray = getCurrenciesList(action.payload)
            } catch (e) {
                console.log(e)
            }
            return {
                ...state,
                countries: action.payload,
                countriesSelect: countryArray,
                currenciesSelect: currenciesArray,
                axiosErrors: []
            }
        case SET_HOME_PAGE_DATE:
            return {
                ...state,
                homePageDate: action.payload,
            }
        case GET_TOTAL_COUNTRUIES:
            return {
                ...state,
                totalCountriesSelect: action.payload.selectList,
                totalCountries: action.payload.list
            }
        case ERROR_NOTIFICATION:
            if (action.payload.addError)
                return {
                    ...state,
                    axiosErrors: [
                        ...state.axiosErrors,
                        {
                            title: action.payload.error.title + ' 😕',
                            desc: action.payload.error.content
                        }
                    ]
                }
            else {
                state.axiosErrors.shift()
                return state
            }
        case GET_LANGUAGES_LIST:
            return {
                ...state,
                languages: action.payload
            }
        case SET_TOTAL_CURRENCIES:
            return {
                ...state,
                totalCurrenciesSelect: action.payload.selectList,
                currencies: action.payload.list,
            }
        case TOGGLE_SOCKET:
            return {
                ...state,
                socketConnected: action.payload
            }
        case SET_NOTES:
            return {
                ...state,
                notes: action.payload
            }
        case SET_PAY_REQUESTS_COUNT:
            return {
                ...state,
                currentPaymentRequestsData:{
                    ...state.currentPaymentRequestsData,
                    count: action.payload
                }
            }
            case SET_PAGES:
                return {
                    ...state,
                    // pages
                }
        default:
            return state
    }
}