import React from 'react'

import '../assets/styles/loading.css'
import logo from '../assets/images/Logo.png'
import { motion } from 'framer-motion'

export default function Loading() {
  return (
    // <div className="loading">
    //   <div></div>
    // </div>
    <div className="loading">
      <motion.div
        transition={{ duration: 1.5, repeat: Infinity }}
        animate={{
          scale: [1, 2, 1],
          rotate: [0, 180, 360],
          borderRadius: ["20%", "20%", "50%", "50%", "20%"],
        }}
      >
        <img src={logo} />
      </motion.div>
    </div>
    // <div className="loading">
    //   <div>
    //     <img src={logo} />
    //   </div>
    // </div>
  )
}
