import {
    createStore,
    applyMiddleware
} from "redux";
import {
    rootReducer
} from "./reducers";
import {
    persistStore,
    persistReducer,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import ReduxThunk from 'redux-thunk';


const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

function configureStore() {
    let store = createStore(persistedReducer, applyMiddleware(ReduxThunk))
    let persistor = persistStore(store)
    return {
        store,
        persistor
    }
}
export default configureStore;

// export default createStore(rootReducer);