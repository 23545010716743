import {
    PAYMENTS_LOADING,
    GET_PAYMENTS_LIST,
    GET_PAYMENT_TYPES,
    UPDATE_DELIVER_STATUS,
    PAGINATE_PAYMENTS_LIST,
} from '../actionTypes'
import {getPaymentTypesSelectList} from '../../helpers/InputSelectHelper'

let initState = {
    paymentsLoading: false,
    paymentsList: [],
    paymentTypes: [],
    deliverStatus: [
        { value: '0', label: 'Pay' },
        { value: '1', label: 'Deliver' },
    ],
    initialPage:0,
    PageNumber: 0,
    pageCount: 1,
}

export const payMethodsReducer = (state = initState, action) => {
    switch (action.type) {
        case PAYMENTS_LOADING:
            return {
                ...state,
                paymentsLoading: action.payload
            }
        case GET_PAYMENTS_LIST:
            return {
                ...state,
                paymentsList: action.payload,
                pageCount: action.payload[0].PageCount,
            }
        case GET_PAYMENT_TYPES:
            let newArray = getPaymentTypesSelectList(action.payload)
            return {
                ...state,
                paymentTypes: newArray
            }
        case UPDATE_DELIVER_STATUS:
            return {
                ...state,
                deliverStatus: action.payload
            }
        case PAGINATE_PAYMENTS_LIST:
            return {
                ...state,
                PageNumber: action.payload
            }
        default:
            return state
    }
}