import Storages from '../../helpers/Storages'
import {
    SET_USERS,
    SET_USER_ID,
    SET_RECEIVERS,
    INIT_BASE_COUNTRY,
    SET_TEMP_RECEIVERS,
    TOGGLE_TEMP_RECEIVERS_WAITING,
    SET_ADD_RECEIVERS_ERRORS,
    CLEAR_ADD_RECEIVERS_ERRORS,
    SET_ADD_RECEIVERS_WAITING,
    SET_SUM_PAY_AMOUNT,
    SET_FORMAT_LIST,
    SET_FORMAT_STRING,
    SET_SUM_PAY_AMOUNT_WAITIMNG,
    SET_BANK_LIST,
    TOGGLE_BASE_CURRENCY_LOADING,
    SET_ORIGIN_COUNTRY,
    TOGGLE_CONVERT_CURRENCY_LOADING,
    SET_DEST_COUNTRY,
    SET_ORIGIN_COUNTRY_CURENCY,
    SET_DEST_COUNTRY_CURENCY,
    CLEAR_CHEQUE_STORE,
    TOGGLE_CURRENCY_RATE,
    SET_CURRENCY_RATE,
    SET_CURRENCY_RATE_MSG,
    SET_CURRENCY_RATE_EDIT,
    CALC_AMOUNT_RECEIVE,
    SET_AMOUNT_RECEIVE,
    SET_AMOUNT_SENT,
    CALC_AMOUNT_SENT,
    HANDLE_CHEQUE_NEW_RECEIVER,
    TOGGLE_ADD_NEW_RECEIVER,
    HANDLE_INPUT_SELECT_CHEQUE,
    ADD_RECEIVER,
    ADD_TO_RECEIVERS,
    SET_PAY_TYPES,
    SET_PAY_TYPE_ID,
    SET_RECEIVE_TYPES,
    SET_TEMP_TRANSFER_ID,
    TOGGLE_SEVE_INFO_WAITING,
    CHEQUE_ADDED,
    TOGGLE_ADD_NEW_DEPOSIT_MODAL,
    SET_TRANSFER_ID,
} from '../actionTypes'

let initState = {
    data:{
        UserId: '',
        BaseCurrencyId: null,
        ConvertCurrencyId: null,
        BaseCountryId: '',
        ConvertCountryId: '',
        amountSent: '',
        amountReceived: '',
        currencyRate: '',
        PaymentTypeId: '',
        ReceiveTypeId: '',
        PayTypes: [],
        ReceiveTypes: [],
        TransferFee: '',
        ExtraTransactionCost: '',
        TempTransferId: '',
        TempReceiver:{
            AccountNumber: '',
            BankName: '',
            Email: '',
            FirstName: '',
            CustomerId: '',
            ImgUrl: '',
            LastName: '',
            MiddleName: '',
            MobilePhone: '',
            CustomerAccountId: '',
        }
    },
    DeliveryTime:'',
    showAddNewReceiverFlag: false,
    baseCurrencyLoading: false,
    convertCurrencyLoading: false,
    currencyRateLoading: false,
    Receivers: [],
    TempReceivers: [],
    TempReceiversWiating:false,
    ReceiverId: '',
    SumPayAmount: '',
    RestPayAmount: '',
    receiversEntiry: [],
    PropertyFormatList: [],
    PropertyFormatString: [],
    usersSelect: [],
    receiversSelect: [],
    banksEntiry: [],
    banksSelect: [],
    currencyRateMsg: '',
    SumPayAmountWaiting: false,
    AddReceiverWaiting: false,
    SaveInfoWaiting: false,
    AddReceiverErrors: [],
    chequeAdded: false,
    showAddNewDepositFlag: false,
    transferId: '',
    paymentAmount: '',
}

export const chequeReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_USERS:
            return {
                ...state,
                usersSelect: action.payload
            }
        case SET_USER_ID:
            return {
                ...state,
                data:{
                    ...state.data,
                    UserId: action.payload,
                }
            }
        case SET_RECEIVERS:
            return {
                ...state,
                receiversSelect: action.payload.receiversSelect,
                receiversEntiry: action.payload.receiversEntiry
            }
        case INIT_BASE_COUNTRY:
            return {
                ...state,
                data:{
                    ...state.data,
                    BaseCountryId:{ value: Storages.getCountryId(), label: action.payload.filter(item => item.countryId == Storages.getCountryId())[0]?.Title }
                }
            }
        case SET_FORMAT_LIST:
            return {
                ...state,
                PropertyFormatList: action.payload
            }
        case SET_FORMAT_STRING:
            return {
                ...state,
                PropertyFormatString: action.payload
            }
        case SET_TEMP_RECEIVERS:
            return {
                ...state,
                TempReceivers: action.payload,
            }
        case TOGGLE_TEMP_RECEIVERS_WAITING:
            return {
                ...state,
                TempReceiversWiating: action.payload,
            }
        case SET_ADD_RECEIVERS_ERRORS:
            return {
                ...state,
                AddReceiverErrors: action.payload,
            }
        case CLEAR_ADD_RECEIVERS_ERRORS:
            return {
                ...state,
                AddReceiverWaiting: false,
                AddReceiverErrors: [],
            }
        case SET_ADD_RECEIVERS_WAITING:
            return {
                ...state,
                AddReceiverWaiting: action.payload,
            }
        case SET_SUM_PAY_AMOUNT:
            let value = state.data.amountReceived - action.payload
            return {
                ...state,
                SumPayAmount: action.payload,
                RestPayAmount: value,
            }
        case SET_SUM_PAY_AMOUNT_WAITIMNG:
            return {
                ...state,
                SumPayAmountWaiting: action.payload,
            }
        case SET_BANK_LIST:
            return {
                ...state,
                banksSelect: action.payload.banksSelect,
                banksEntiry: action.payload.banksEntiry,
            }
        case TOGGLE_ADD_NEW_RECEIVER:
            return {
                ...state,
                showAddNewReceiverFlag: action.payload,
            }
        case TOGGLE_BASE_CURRENCY_LOADING:
            return {
                ...state,
                baseCurrencyLoading: action.payload,
            }
        case SET_ORIGIN_COUNTRY:
            return {
                ...state,
                data:{
                    ...state.data,
                    BaseCountryId: action.payload
                }
            }
        case TOGGLE_CONVERT_CURRENCY_LOADING:
            return {
                ...state,
                convertCurrencyLoading: action.payload,
            }
        case SET_DEST_COUNTRY:
            return {
                ...state,
                data:{
                    ...state.data,
                    ConvertCountryId: action.payload
                }
            }
        case SET_ORIGIN_COUNTRY_CURENCY:
            return {
                ...state,
                data:{
                    ...state.data,
                    BaseCurrencyId: action.payload
                }
            }
        case SET_DEST_COUNTRY_CURENCY:
            return {
                ...state,
                data:{
                    ...state.data,
                    ConvertCurrencyId: action.payload
                }
            }
        case TOGGLE_CURRENCY_RATE:
            return {
                ...state,
                currencyRateLoading: action.payload
            }
        case SET_CURRENCY_RATE:
            return {
                ...state,
                data:{
                    ...state.data,
                    currencyRate: action.payload?.ExChangeRate,
                    ExtraTransactionCost: action.payload?.ExtraTransactionCost,
                    TransferFee: action.payload?.TransferFee,
                    amountReceived: '',
                    amountSent: '',
                },
                DeliveryTime: action.payload.DeleveryTime,
                currencyRateMsg: ''
            }
        case SET_CURRENCY_RATE_MSG:
            return {
                ...state,
                currencyRateMsg: action.payload
            }
        case SET_CURRENCY_RATE_EDIT:
            return {
                ...state,
                data:{
                    ...state.data,
                    currencyRate: action.payload,
                },
            }
        case HANDLE_CHEQUE_NEW_RECEIVER:
            return {
                ...state,
                data:{
                    ...state.data,
                   TempReceiver:{
                       ...state.data.TempReceiver,
                       [action.payload.name]: action.payload.value
                   }
                }
            }
        case HANDLE_INPUT_SELECT_CHEQUE:
            return {
                ...state,
                data:{
                    ...state.data,
                    [action.payload.name]: action.payload.value
                }
            }
        case ADD_RECEIVER:
            return {
                ...state,
                ReceiverId: action.payload,
            }
        case ADD_TO_RECEIVERS:
            return {
                ...state,
                Receivers:[
                    ...state.Receivers,
                    action.payload
                ]
            }
        case SET_PAY_TYPES:
            return{
                ...state,
                data:{
                    ...state.data,
                    PayTypes: action.payload,
                    PaymentTypeId: action.payload[0].value
                }
            }
        case SET_PAY_TYPE_ID:
            return{
                ...state,
                data:{
                    ...state.data,
                    PaymentTypeId: action.payload
                }
            }
        case SET_RECEIVE_TYPES:
            return{
                ...state,
                data:{
                    ...state.data,
                    ReceiveTypes: action.payload,
                    ReceiveTypeId: action.payload[0].value
                }
            }
        case CALC_AMOUNT_RECEIVE:
            return {
                ...state,
                data:{
                    ...state.data,
                    [action.payload.item.name]: action.payload.item.value,
                    amountReceived: action.payload.amountReceived
                }
            }
        case SET_AMOUNT_RECEIVE:
            return {
                ...state,
                data:{
                    ...state.data,
                    amountReceived: action.payload
                }
            }
        case SET_AMOUNT_SENT:
            return {
                ...state,
                data:{
                    ...state.data,
                    amountSent: action.payload
                }
            }
        case CALC_AMOUNT_SENT:
            return {
                ...state,
                data:{
                    ...state.data,
                    [action.payload.item.name]: action.payload.item.value,
                    amountSent: action.payload.amountSent
                }
            }
        case SET_TEMP_TRANSFER_ID:
            return {
                ...state,
                data:{
                    ...state.data,
                    TempTransferId: action.payload
                }
            }
        case TOGGLE_SEVE_INFO_WAITING:
            return {
                ...state,
                SaveInfoWaiting: action.payload
            }
        case CHEQUE_ADDED:
            return {
                ...state,
                chequeAdded: true
            }
        case TOGGLE_ADD_NEW_DEPOSIT_MODAL:
            return {
                ...state,
                chequeAdded: true,
                showAddNewDepositFlag: true
            }
        case SET_TRANSFER_ID:
            return {
                ...state,
                transferId: action.payload.id,
                paymentAmount: action.payload.amount
            }

        case CLEAR_CHEQUE_STORE:
            return initState
        default:
            return state
    }
}