import {
    GET_COUNTRIES_LIST,
    COUNTRIES_DOCS,
    SET_COUNTRIE_ID,
    DOCS_TYPE,
    DOC_FIELD,
    FIELD_TYPES,
    WAITITNG,
    DOCS_FIELDS_LOADING,
    COUNTRIES_DOCS_LOADING,
    DOC_FIELD_DETAILS_LOADING,
    DOC_FIELD_DETAILS,
    TOGGLE_COUNTRY_DOCS_LANG_MODAL,
} from "../actionTypes"


let initState = {
    isWaiting: true,
    countryId: '',
    countries: [],
    countriesDocs: [],
    countriesDocsLoadign: false,
    languages: [],
    docsFields: [],
    docsFieldsLoading: false,
    docsType: [],
    fieldTypes: [],
    countryDocFieldDetails: [],
    countryDocFieldDetailsLoading: false,
    countryDocsLanguagesModalFlag: false
}

export const docsReducer = (state = initState, action) => {
    switch (action.type) {
        case GET_COUNTRIES_LIST:
            return {
                ...state,
                countries: action.payload
            }
        case COUNTRIES_DOCS:
            return {
                ...state,
                countriesDocs: action.payload,
            }
        case SET_COUNTRIE_ID:
            return {
                ...state,
                countryId: action.payload,
            }
        case DOCS_TYPE:
            return {
                ...state,
                docsType: action.payload,
                isWaiting: false
            }
        case DOC_FIELD:
            return {
                ...state,
                docsFields: action.payload,
            }
        case FIELD_TYPES:
            return {
                ...state,
                fieldTypes: action.payload,
            }
        case WAITITNG:
            return {
                ...state,
                isWaiting: action.payload
            }
        case DOCS_FIELDS_LOADING:
            return {
                ...state,
                docsFieldsLoading: action.payload
            }
        case COUNTRIES_DOCS_LOADING:
            return {
                ...state,
                countriesDocsLoadign: action.payload
            }
        case DOC_FIELD_DETAILS_LOADING:
            return {
                ...state,
                countryDocFieldDetailsLoading: action.payload
            }
        case DOC_FIELD_DETAILS:
            return {
                ...state,
                countryDocFieldDetails: action.payload
            }
        case TOGGLE_COUNTRY_DOCS_LANG_MODAL:
            return {
                ...state,
                countryDocsLanguagesModalFlag: action.payload
            }
        default: return state
    }
}