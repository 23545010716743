let Storages = (() => {
    let userToken = 'userToken'
    let userId = 'userId'
    let userMail = 'userMail'
    let userPass = 'userPass'
    let userName = 'userName'
    let userMiddleName = 'userMiddleName'
    let userLastName = 'userLastName'
    let userGender = 'userGender'
    let userBirthday = 'userBirthday'
    let phoneNumber = 'phoneNumber'
    let countryId = 'countryId'
    let langId = 'langId'
    let direction = 'direction'
    let lang = 'lang'
    let profileImage = 'profileImage'

    let setUserToken = (token) => {
        try{
            localStorage.setItem(userToken, token)
        }catch(e){
            console.log(e)
        }
    }
    let getUserToken = () => {
        try{
            return localStorage.getItem(userToken)
        }catch(e){
            console.log(e)
        }
    }
    let setUserId = (id) => {
        try{
            localStorage.setItem(userId, id)
        }catch(e){
            console.log(e)
        }
    }
    let getUserId = () => {
        try{
            return localStorage.getItem(userId)
        }catch(e){
            console.log(e)
        }
    }
    let setUserMail = (mail) => {
        try{
            localStorage.setItem(userMail, mail)
        }catch(e){
            console.log(e)
        }
    }
    let getUserMail = () => {
        try{
            return localStorage.getItem(userMail)
        }catch(e){
            console.log(e)
        }
    }
    let setUserPass = (pass) => {
        try{
            localStorage.setItem(userPass, pass)
        }catch(e){
            console.log(e)
        }
    }
    let getUserPass = () => {
        try{
            return localStorage.getItem(userPass)
        }catch(e){
            console.log(e)
        }
    }
    let setUserName = (name) => {
        try{
            localStorage.setItem(userName, name)
        }catch(e){
            console.log(e)
        }
    }
    let getUserName = () => {
        try{
            return localStorage.getItem(userName)
        }catch(e){
            console.log(e)
        }
    }
    let setUserMiddelName = (middleName) => {
        try{
            localStorage.setItem(userMiddleName, middleName)
        }catch(e){
            console.log(e)
        }
    }
    let getUserMiddelName = () => {
        try{
            return localStorage.getItem(userMiddleName)
        }catch(e){
            console.log(e)
        }
    }
    let setUserLastName = (lastName) => {
        try{
            localStorage.setItem(userLastName, lastName)
        }catch(e){
            console.log(e)
        }
    }
    let getUserLastName = () => {
        try{
            return localStorage.getItem(userLastName)
        }catch(e){
            console.log(e)
        }
    }
    let setUserGender = (gender) => {
        try{
            localStorage.setItem(userGender, gender)
        }catch(e){
            console.log(e)
        }
    }
    let getUserGender = () => {
        try{
            return localStorage.getItem(userGender)
        }catch(e){
            console.log(e)
        }
    }
    let setUserBirthday = (birthday) => {
        try{
            localStorage.setItem(userBirthday, birthday)
        }catch(e){
            console.log(e)
        }
    }
    let getUserBirthday = () => {
        try{
            return localStorage.getItem(userBirthday)
        }catch(e){
            console.log(e)
        }
    }
    let setUserPhone = (phone) => {
        try{
            localStorage.setItem(phoneNumber, phone)
        }catch(e){
            console.log(e)
        }
    }
    let getUserPhone = () => {
        try{
            return localStorage.getItem(phoneNumber)
        }catch(e){
            console.log(e)
        }
    }
    let setCountryId = (country) => {
        try{
            localStorage.setItem(countryId, country)
        }catch(e){
            console.log(e)
        }
    }
    let getCountryId = () => {
        try{
            return localStorage.getItem(countryId)
        }catch(e){
            console.log(e)
        }
    }

    let setLang = (la) => {
        try{
            localStorage.setItem(lang, la)
        }catch(e){
            console.log(e)
        }
    }
    let getLang = () => {
        try{
            return localStorage.getItem(lang) ?? 'en'
        }catch(e){
            console.log(e)
        }
    }

    let setLangId = (id) => {
        try{
            localStorage.setItem(langId, id)
        }catch(e){
            console.log(e)
        }
    }
    let getLangId = () => {
        try{
            return localStorage.getItem(langId)
        }catch(e){
            console.log(e)
        }
    }

    let setProfileImage = (fileId) => {
        try{
            localStorage.setItem(profileImage, fileId)
        }catch(e){
            console.log(e)
        }
    }
    let getProfileImage = () => {
        try{
            return localStorage.getItem(profileImage)
        }catch(e){
            console.log(e)
        }
    }

    let setDir = (dir) => {
        try{
            localStorage.setItem(direction, dir)
        }catch(e){
            console.log(e)
        }
    }
    let getDir = () => {
        try{
            return localStorage.getItem(direction)
        }catch(e){
            console.log(e)
        }
    }

    let removeProfile = () => {
        localStorage.removeItem(userToken)
        localStorage.removeItem(userMail)
        localStorage.removeItem(userPass)
        localStorage.removeItem(userName)
    }

    return {
        setUserToken: setUserToken,
        getUserToken: getUserToken,
        setUserId: setUserId,
        getUserId: getUserId,
        setUserMail: setUserMail,
        getUserMail: getUserMail,
        setUserPass: setUserPass,
        getUserPass: getUserPass,
        setUserName: setUserName,
        getUserName: getUserName,
        setUserMiddelName: setUserMiddelName,
        getUserMiddelName: getUserMiddelName,
        setUserLastName: setUserLastName,
        getUserLastName: getUserLastName,
        setUserGender: setUserGender,
        getUserGender: getUserGender,
        setUserBirthday: setUserBirthday,
        getUserBirthday: getUserBirthday,
        setUserPhone: setUserPhone,
        getUserPhone: getUserPhone,
        setCountryId: setCountryId,
        getCountryId: getCountryId,
        setLang: setLang,
        getLang: getLang,
        setLangId: setLangId,
        getLangId: getLangId,
        setDir: setDir,
        getDir: getDir,
        setProfileImage: setProfileImage,
        getProfileImage: getProfileImage,
        removeProfile: removeProfile,
    }
})()

export default Storages