import {
    useEffect,
    useState
} from "react"
import {
    useSelector
} from "react-redux"

import Storages from "../helpers/Storages"

function useEndpoints() {
    const [state, setState] = useState(Storages.getLang())
    const local = useSelector(state => state.appReducer.local)

    useEffect(() => {
        setState(Storages.getLang())
    }, [local])

    // var socketApi = 'http://192.168.2.60:8070'
    // var socketApi = 'https://bumbusly.com:3001'
    // var socketApi = 'https://bumbusly.com:3000'
    var socketApi = process.env.REACT_APP_SOCKET_PATH

    var webService = process.env.REACT_APP_API_PATH + state + '/'
    //var webService = 'https://bumbusly.com/api/v2/' + state + '/'
    // var webService = 'https://bumbusly.com:8443/api/v2/' + state + '/'
    // var webService = 'http://192.168.2.60:8073/api/v2/' + state + '/'

    const endpoints = {

        socketApi: socketApi,
        webSrvice: webService,
        crashReport: webService + "crash-report",
        signUp: webService + "authentication/sign-up",
        signIn: webService + "authentication/sign-in",
        verify: webService + "authentication/verify",
        forgetPassword: webService + "authentication/forget-password",
        resetPassword: webService + "authentication/reset-password",
        info: webService + "info",
        countris: webService + "info/countris",
        getPages: webService + "info/pages",
        mailFormat: webService + "panel/info/mail-format",
        pages: webService + "panel/info/pages",

        roles: webService + "panel/manage/roles",
        roleAccess: webService + "panel/manage/role-access",
        docType: webService + "panel/doc-type",
        docTypeAccept: webService + "panel/doc-type/accept",
        docFields: webService + "panel/doc-fields",
        docFieldsAccept: webService + "panel/doc-fields/accept",
        docFieldLang: webService + "panel/doc-field-lng",
        fieldTypes: webService + "panel/field-types",
        countriesDocs: webService + "panel/countries-docs",
        countriesDocsOnlineIdentity: webService + "panel/countries-docs/online-identity",
        countriesLang: webService + "panel/countries-docs/lng",
        languages: webService + "panel/languages",
        users: webService + "panel/user",
        userNote: webService + "panel/user/note",
        userProfile: webService + "panel/user/profile",
        userVerification: webService + "panel/user/verification",
        customers: webService + "panel/user/customer",
        customerAccount: webService + "panel/user/customer/accounts",
        agents: webService + "panel/user/agent",
        approval: webService + "panel/user/approval",
        balckList: webService + "panel/user/black-list",
        paymentMethod: webService + "panel/payment/methods",
        paymentTypes: webService + "panel/payment/types",
        payment: webService + "panel/payment",
        exchangeRate: webService + "panel/exchange-rate",
        panelInfo: webService + "panel/info",
        countryCurrency: webService + "panel/info/country-currency",
        manageCountris: webService + "panel/info/countris",
        currency: webService + "panel/info/currency",
        paymentRequestAgent: webService + "panel/transfer/payment-request-agent",
        paymentPayers: webService + "panel/transfer/payment-payers",
        currencyRate: webService + "panel/transfer/currency-rate",
        customerPaymentHistory: webService + "panel/transfer/customer-payment-history",
        paymentRequest: webService + "panel/transfer/payment-request",
        sumPayAmount: webService + "panel/transfer/sum-pay-amount",
        transfer: webService + "panel/transfer",
        transferStatus: webService + "panel/transfer/status",
        originPayment: webService + "panel/transfer/origin-payment",
        originPaymentDeposit: webService + "panel/transfer/origin-payment/deposit",
        originPaymentManagerAccounts: webService + "panel/transfer/origin-payment/manager-accounts",
        originPaymentAgentAccounts: webService + "panel/transfer/origin-payment/agent-accounts",
        originPaymentReceipts: webService + "panel/transfer/origin-payment/receipts",
        paymentAccounts: webService + "panel/transfer/origin-payment/payment-accounts",
        requestPaymentManage: webService + "panel/transfer/payment-request-manage",
        transferDetails: webService + "panel/transfer/details",
        transferDetailsDestReceipts: webService + "panel/transfer/details/destination-receipts",
        transferDetailsOrigin: webService + "panel/transfer/details/origin-receipts",
        transferDetailsDestPayment: webService + "panel/transfer/details/destination-payment",
        transferDetailsOriginPayment: webService + "panel/transfer/details/origin-payment",
        receiptsRequest: webService + "panel/transfer/details/receipts-request",
        paymentReceipt: webService + "panel/transfer/payment-receipt",
        acceptReceipt: webService + "panel/transfer/accept-receipt",
        trade: webService + "panel/trade",
        userTrade: webService + "panel/trade/user-trade",
        tradeRequest: webService + "panel/trade/trade-request",
        tradeCurrency: webService + "panel/trade/currency",
        tradeOffer: webService + "panel/trade/offer",
        tradeMyTrade: webService + "panel/trade/my-trade",
        account: webService + "panel/account",
        accountDetails: webService + "panel/account/details",
        cash: webService + "panel/account/cash",
        dashboard: webService + "panel/account/dashboard",
        turnover: webService + "panel/account/turn-over",
        reportReceipt: webService + "panel/report/receipts",
        mail: webService + "panel/mail",

        bank: webService + "panel/bank",
        navasan: webService + "panel/navasan",
        navasanEnactment: webService + "panel/navasan/enactment",
        managerAccount: webService + "panel/bank/manager-account",

        fileIO: webService + "filio",
        fileIOPrivate: webService + "filio/private",
    }
    return endpoints
}

export default useEndpoints