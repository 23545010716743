import axios from "axios"
import {
    getUsersList,
    getReceiversList,
    getPaymentMethodsSelectList,
    getBankList,
} from "../../helpers/InputSelectHelper"
import Storages from "../../helpers/Storages"

import {
    SET_USERS,
    SET_USER_ID,
    SET_RECEIVERS,
    INIT_BASE_COUNTRY,
    SET_TEMP_RECEIVERS,
    TOGGLE_TEMP_RECEIVERS_WAITING,
    SET_ADD_RECEIVERS_ERRORS,
    CLEAR_ADD_RECEIVERS_ERRORS,
    SET_ADD_RECEIVERS_WAITING,
    SET_SUM_PAY_AMOUNT,
    SET_BANK_LIST,
    TOGGLE_BASE_CURRENCY_LOADING,
    SET_ORIGIN_COUNTRY,
    TOGGLE_CONVERT_CURRENCY_LOADING,
    SET_DEST_COUNTRY,
    SET_FORMAT_LIST,
    SET_FORMAT_STRING,
    SET_ORIGIN_COUNTRY_CURENCY,
    SET_DEST_COUNTRY_CURENCY,
    CLEAR_CHEQUE_STORE,
    TOGGLE_CURRENCY_RATE,
    SET_CURRENCY_RATE,
    SET_CURRENCY_RATE_MSG,
    SET_CURRENCY_RATE_EDIT,
    CALC_AMOUNT_RECEIVE,
    SET_AMOUNT_RECEIVE,
    SET_AMOUNT_SENT,
    CALC_AMOUNT_SENT,
    HANDLE_CHEQUE_NEW_RECEIVER,
    HANDLE_INPUT_SELECT_CHEQUE,
    TOGGLE_ADD_NEW_RECEIVER,
    ADD_RECEIVER,
    ADD_TO_RECEIVERS,
    SET_PAY_TYPES,
    SET_PAY_TYPE_ID,
    SET_RECEIVE_TYPES,
    SET_TEMP_TRANSFER_ID,
    SET_SUM_PAY_AMOUNT_WAITIMNG,
    TOGGLE_SEVE_INFO_WAITING,
    CHEQUE_ADDED,
    TOGGLE_ADD_NEW_DEPOSIT_MODAL,
    SET_TRANSFER_ID,
} from '../actionTypes'

export const getUsers = (endpoint) => {
    return (dispatch) => {
        axios.get(endpoint, {
            params: { OnlyIdentityConfirmed: true },
            headers: {
                'x-auth-token': Storages.getUserToken()
            }
        }).then(res => {
            try {
                if (res.data.status) {
                    dispatch(setUsers(getUsersList(res.data.result)))
                    let user = res.data.result.filter(item=>item.Id == Storages.getUserId())[0]
                    dispatch(setUserId({value: user.Id, label: `${user?.FirstName} ${user?.LastName}`}))
                }
            } catch (e) {
                console.log(e)
            }
        }).catch(e => {
            console.log(e)
        })
    }
}

export const setUsers = (list) => ({
    type: SET_USERS,
    payload: list
})

export const setUserId = (userId) => ({
    type: SET_USER_ID,
    payload: userId
})

export const initBaseCountry = (countries) => ({
    type: INIT_BASE_COUNTRY,
    payload: countries
})

export const toggleAddNewReceiverModal = (status) => ({
    type: TOGGLE_ADD_NEW_RECEIVER,
    payload: status
})

export const getReceivers = (endpoint, UserId, CountryId, t) => {
      return (dispatch) => {
        axios.get(endpoint, {
            params: {
                UserId: UserId,
                CountryId: CountryId,
            },
            headers: {
                'x-auth-token': Storages.getUserToken()
            }
        }).then(res => {
            try {
                if (res.data.status) {
                    dispatch(setReceivers(getReceiversList(res.data.result.CustomerHistory, t), res.data.result.CustomerHistory))
                    try {
                        // dispatch(setFormatList(res.data.result[0]?.PropertyFormatList?.split(',')))
                        dispatch(setFormatList(res.data.result?.PropertyFormatList?.split(',')))
                    } catch (e) {
                        console.log(e)
                    }
                }
            } catch (e) {
                console.log(e)
            }
        }).catch(e => {
            console.log(e)
        })
    }
}

export const setReceivers = (receiversSelect, receiversEntiry) => ({
    type: SET_RECEIVERS,
    payload: {
        receiversSelect: receiversSelect,
        receiversEntiry: receiversEntiry
    }
})

export const setFormatList = (list) => ({
    type: SET_FORMAT_LIST,
    payload: list
})

export const setFormatString = (list) => ({
    type: SET_FORMAT_STRING,
    payload: list
})

export const getTempReceivers = (endpoint, TempTransferId)  => {
    return (dispatch) => {
        dispatch(toggleTempReceiversWaiting(true))
        axios.get(endpoint, {
            params: {
                TempTransferId: TempTransferId
            },
            headers: {
                'x-auth-token': Storages.getUserToken()
            }
        }).then(res => {
            try {
                if (res.data.status) {
                    dispatch(setTempReceivers(res.data.result))
                    dispatch(toggleTempReceiversWaiting(false))
                }
            } catch (e) {
                console.log(e)
            }
        }).catch(e => {
            console.log(e)
        })
    }
}

export const toggleTempReceiversWaiting = (status) => ({
    type: TOGGLE_TEMP_RECEIVERS_WAITING,
    payload: status
})

export const setTempReceivers = (TempReceivers) => ({
    type: SET_TEMP_RECEIVERS,
    payload: TempReceivers
})

export const setAddReceiverError = (errors) => ({
    type: SET_ADD_RECEIVERS_ERRORS,
    payload: errors
})

export const clearAddReceiverError = () => ({
    type: CLEAR_ADD_RECEIVERS_ERRORS,
})

export const toggleAddReceiverWaiting = (status) => ({
    type: SET_ADD_RECEIVERS_WAITING,
    payload: status
})

export const addTempReceiver = (endpoint, userInfo) => {
    return (dispatch) => {
        dispatch(toggleAddReceiverWaiting(true))
        axios.post(endpoint.paymentRequest, userInfo, {
            headers: {
                'x-auth-token': Storages.getUserToken()
            }
        }).then(res => {
            try {
                if (res.data.status) {
                    dispatch(setTempTransferId(res.data.result.TempTransferId))
                    dispatch(toggleAddNewReceiverModal(false))
                    dispatch(getSumPayAmount(endpoint.sumPayAmount, res.data.result.TempTransferId))
                }
            } catch (e) {
                console.log(e)
            }
        }).catch(e => {
            dispatch(toggleAddReceiverWaiting(false))
            if (e?.response?.status === 421) {
                dispatch(setAddReceiverError(e.response.data.errors))
                return
            }
        })
    }
}

export const setTempTransferId = (id) => ({
    type: SET_TEMP_TRANSFER_ID,
    payload: id
})

export const getSumPayAmount = (endpoint, TempTransferId) => {
    return (dispatch) => {
        dispatch(toggleSumPayAmountWaiting(true))
        axios.get(endpoint, {
            params: {
                TempTransferId: TempTransferId
            },
            headers: {
                'x-auth-token': Storages.getUserToken()
            }
        }).then(res => {
            try {
                if (res.data.status) {
                    dispatch(setSumPayAmount(res.data.result.TotalPayment))
                    dispatch(toggleSumPayAmountWaiting(false))
                }
            } catch (e) {
                console.log(e)
            }
        }).catch(e => {
            
        })
    }
}

export const toggleSumPayAmountWaiting = (status) => ({
    type: SET_SUM_PAY_AMOUNT_WAITIMNG,
    payload: status
})

export const setSumPayAmount = (sumPay) => ({
    type: SET_SUM_PAY_AMOUNT,
    payload: sumPay
})


export const getBank = (endpoint, CountryId) => {
    return (dispatch) => {
        axios.get(endpoint, {
            params: {
                CountryId: CountryId,
            },
            headers: {
                'x-auth-token': Storages.getUserToken()
            }
        }).then(res => {
            try {
                if (res.data.status) {
                    let newArray = getBankList(res.data.result)
                    dispatch(setBankList(newArray, res.data.result))
                }
            } catch (e) {
                console.log(e)
            }
        }).catch(e => {
            console.log(e)
        })
    }
}

export const setBankList = (banksSelect, banksEntiry) => ({
    type: SET_BANK_LIST,
    payload: {
        banksSelect: banksSelect,
        banksEntiry: banksEntiry
    }
})

export const getOriginCountryCurency = (endpoint, item) => {
    return (dispatch) => {
        dispatch(toggleBaseCurrencyLoading(true))
        dispatch(setOriginCountry(item))
        axios.get(endpoint.countryCurrency, {
            params: {
                CountryId: item.value
            },
            headers: {
                'x-auth-token': Storages.getUserToken()
            }
        }).then(res => {
            try {
                if (res.data.status) {
                    dispatch(toggleBaseCurrencyLoading(false))
                    dispatch(setOriginCountryCurrency({
                        value: res.data.result?.CurrencyId,
                        label: res.data.result?.SymbolText}))
                    dispatch(getPayReceiveType(endpoint.paymentMethod, 0, item.value))
                }
            } catch (e) {
                console.log(e)
            }
        }).catch(e => {
            console.log(e)
        })
    }
}

export const toggleBaseCurrencyLoading = (status) => ({
    type: TOGGLE_BASE_CURRENCY_LOADING,
    payload: status
})

export const setOriginCountry = (country) => ({
    type: SET_ORIGIN_COUNTRY,
    payload: country
})

export const setOriginCountryCurrency = (currency) => ({
    type: SET_ORIGIN_COUNTRY_CURENCY,
    payload: currency
})

export const getDestCountryCurency = (endpoint, item) => {
    return (dispatch) => {
        dispatch(toggleConverCurrencyLoading(true))
        dispatch(setDestinationCountry(item))
        axios.get(endpoint.countryCurrency, {
            params: {
                CountryId: item.value
            },
            headers: {
                'x-auth-token': Storages.getUserToken()
            }
        }).then(res => {
            try {
                if (res.data.status) {
                    dispatch(toggleConverCurrencyLoading(false))
                    dispatch(setDestCountryCurrency({
                        value: res.data.result?.CurrencyId,
                        label: res.data.result?.SymbolText}))
                    dispatch(
                        getPayReceiveType(endpoint.paymentMethod, 1, item.value),
                        )
                    }
                } catch (e) {
                    console.log(e)
                }
            }).catch(e => {
                console.log(e)
            })
        }
}

export const toggleConverCurrencyLoading = (status) => ({
    type: TOGGLE_CONVERT_CURRENCY_LOADING,
    payload: status
})
    
export const setDestinationCountry = (country) => ({
    type: SET_DEST_COUNTRY,
    payload: country
})

export const setDestCountryCurrency = (currency) => ({
    type: SET_DEST_COUNTRY_CURENCY,
    payload: currency
})

export const getCurrencyRate = (endpoint, BaseCountryId, BaseCurrencyId, ConvertCurrencyId) => {
    return (dispatch) => {
        dispatch(toggleCurrencyRate(true))
        axios.get(endpoint, {
            params: {
                BaseCountryId: BaseCountryId,
                BaseCurrencyId: BaseCurrencyId,
                ConvertCurrencyId: ConvertCurrencyId,
            },
            headers: {
                'x-auth-token': Storages.getUserToken()
            }
        }).then(res => {
            try {
                if (res.data.status) {
                    dispatch(toggleCurrencyRate(false))
                    dispatch(setCurrencyRate(res.data.result))
                }
            } catch (e) {
                console.log(e)
            }
        }).catch(e => {
            console.log(e)
            if (e.response?.data.errors.length === 0 && e.response?.data.content != '') {
                dispatch(setCurrencyRateMessage(e.response.data.content))
            }
        })
    }
}

export const toggleCurrencyRate = (status) => ({
    type: TOGGLE_CURRENCY_RATE,
    payload: status,
})

export const setCurrencyRate = (data) => ({
    type: SET_CURRENCY_RATE,
    payload: data,
})

export const setCurrencyRateMessage = (msg) => ({
    type: SET_CURRENCY_RATE_MSG,
    payload: msg,
})

export const setCurrencyRateEdit = (currencyRate) => ({
    type: SET_CURRENCY_RATE_EDIT,
    payload: currencyRate,
})

export const calcAmountReceive = (name, value, amountReceived) => ({
    type: CALC_AMOUNT_RECEIVE,
    payload: {
        item: {
            name: name,
            value: value
        },
        amountReceived: amountReceived,
    },
})

export const setAmountReceive = (amountReceived) => ({
    type: SET_AMOUNT_RECEIVE,
    payload: amountReceived,
})

export const setAmountSent = (amountSent) => ({
    type: SET_AMOUNT_SENT,
    payload: amountSent,
})

export const calcAmountSent = (name, value, amountSent) => ({
    type: CALC_AMOUNT_SENT,
    payload: {
        item: {
            name: name,
            value: value
        },
        amountSent: amountSent,
    },
})

export const handleChequeNewReceiver = (name, value) => ({
    type: HANDLE_CHEQUE_NEW_RECEIVER,
    payload: {
        name: name,
        value: value,
    },
})

export const handleInputSelectCheque = (name, value) => ({
    type: HANDLE_INPUT_SELECT_CHEQUE,
    payload: {
        name: name,
        value: value,
    },
})


export const getPayReceiveType = (endpoint, PayReceive, CountryId) => {
    return (dispatch) => {
        axios.get(endpoint, {
            params: {
                PayReceive: PayReceive,
                CountryId: CountryId,
            },
            headers: {
                'x-auth-token': Storages.getUserToken()
            }
        }).then(res => {
            try {
                if (res.data.status) {
                    let newArray = getPaymentMethodsSelectList(res.data.result)
                    if (res.data.result[0].PayReceive === 0)
                        dispatch(setPayTypes(newArray))
                    if (res.data.result[0].PayReceive === 1)
                        dispatch(setReceiveTypes(newArray))
                }
            } catch (e) {
                console.log(e)
            }
        }).catch(e => {
            console.log(e)
            if (e.response.data.errors.length === 0 && e.response.data.content != '') {
                dispatch(setCurrencyRateMessage(e.response.data.content))
            }
        })
    }
}

export const addReceiver = (item) => ({
    type: ADD_RECEIVER,
    payload: item
})

export const addToReceivers = (item) => ({
    type: ADD_TO_RECEIVERS,
    payload: item
})

export const setPayTypes = (list) => ({
    type: SET_PAY_TYPES,
    payload: list
})

export const setPayTypeId = (id) => ({
    type: SET_PAY_TYPE_ID,
    payload: id
})

export const setReceiveTypes = (list) => ({
    type: SET_RECEIVE_TYPES,
    payload: list
})

export const clearChequeStore = () => ({
    type: CLEAR_CHEQUE_STORE,
})

export const saveInfo = (endpoints, data, accessList) => {
    return (dispatch) => {
        dispatch(toggleSaveInfoWaiting(true))
        axios.post(endpoints.transfer, data, {
            headers: {
                'x-auth-token': Storages.getUserToken()
            }
        }).then(res => {
            try {
                if (res.data.status) {
                    if(accessList.filter((item)=> item.AccessId == 4)[0].PView == 1){
                        dispatch(setTransferId(res.data.result.PaymentRequestId, res.data.result.PaymentAmount))
                        dispatch(toggleSaveInfoWaiting(false))
                        dispatch(chequeAdded())
                        return
                    }
                    
                    dispatch(clearChequeStore())
                    dispatch(toggleSaveInfoWaiting(false))
                    dispatch(getUsers(endpoints.users))
                }
            } catch (e) {
                console.log(e)
            }
        }).catch(e => {
            console.log(e)
            dispatch(toggleSaveInfoWaiting(false))
            if (e.response.data.errors.length === 0 && e.response.data.content != '') {
                dispatch(setCurrencyRateMessage(e.response.data.content))
            }
        })
    }
}

export const setTransferId = (id,amount) => ({
    type: SET_TRANSFER_ID,
    payload: { id: id, amount: amount},
})

export const chequeAdded=()=>({
    type:CHEQUE_ADDED,
})

export const toggleSaveInfoWaiting = (status) => ({
    type: TOGGLE_SEVE_INFO_WAITING,
    payload: status
})

export const toggleAddNewDepositModal = (status) => ({
    type: TOGGLE_ADD_NEW_DEPOSIT_MODAL,
    payload: status
})