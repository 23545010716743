import axios from 'axios'
import Storages from '../../helpers/Storages'
import {
  TOGGLE_THEME,
  TOGGLE_DRAWER,
  SMALL_SCREEN,
  SET_ACCESS_LIST,
  LOCAL_CHANGE,
  COUTRIES_INFO,
  SET_HOME_PAGE_DATE,
  GET_TOTAL_COUNTRUIES,
  ERROR_NOTIFICATION,
  GET_LANGUAGES_LIST,
  SET_TOTAL_CURRENCIES,
  TOGGLE_SOCKET,
  SET_NOTES,
  SET_PAY_REQUESTS_COUNT,
} from '../actionTypes'

import {
  getCountryList,
  getLanguagesList,
  getTotalCurrencies
} from '../../helpers/InputSelectHelper'

export const toggleTheme = (theme) => ({
  type: TOGGLE_THEME,
  payload: theme,
})

export const toggleDrawer = (status) => ({
  type: TOGGLE_DRAWER,
  payload: status,
})

export const setSmallScreen = (status) => ({
  type: SMALL_SCREEN,
  payload: status,
})

export const setAccessList = (list) => ({
  type: SET_ACCESS_LIST,
  payload: list,
})

export const changeLocal = (local) => ({
  type: LOCAL_CHANGE,
  payload: local,
})

export const countriesInfo = (data) => ({
  type: COUTRIES_INFO,
  payload: data,
})

export const sethomePageData = (data) => ({
  type: SET_HOME_PAGE_DATE,
  payload: data,
})


export const showErrorNotification = (err, status) => ({
  type: ERROR_NOTIFICATION,
  payload: {
    error: err,
    addError: status
  },
})

export const getLanguages = (endpoints, id) => {
  return (dispatch) => {
    axios
      .get(endpoints.languages, {
        params: {
          "CountryId": id
        },
        headers: {
          'x-auth-token': Storages.getUserToken()
        },
      })
      .then((res) => {
        if (res.data.status) {
          let languages = getLanguagesList(res.data.result)
          dispatch(setLanguages(languages))
          return
        }
      })
      .catch((e) => {})
  }
}

export const setLanguages = (list) => ({
  type: GET_LANGUAGES_LIST,
  payload: list,
})

export const getCurrencies = (endpoints) => {
  return (dispatch) => {
    axios
      .get(endpoints, {
        headers: {
          'x-auth-token': Storages.getUserToken()
        },
      })
      .then((res) => {
        if (res.data.status) {
          let currencies = getTotalCurrencies(res.data.result)
          dispatch(setTotalCurrencies(currencies, res.data.result))
          return
        }
      })
      .catch((e) => {})
  }
}

export const setTotalCurrencies = (selectList, list) => ({
  type: SET_TOTAL_CURRENCIES,
  payload: {
    selectList: selectList,
    list: list
  },
})

export const getTotalCountries = (endpoints) => {
  return (dispatch) => {
    axios
      .get(endpoints, {
        params: {
          Active: 0
        },
        headers: {
          'x-auth-token': Storages.getUserToken()
        },
      })
      .then((res) => {
        if (res.data.status) {
          let countries = getCountryList(res.data.result)
          dispatch(setTotalCountries(countries, res.data.result))
          return
        }
      })
      .catch((e) => {})
  }
}

export const setTotalCountries = (selectList, list) => ({
  type: GET_TOTAL_COUNTRUIES,
  payload: {
    selectList: selectList,
    list: list
  },
})

export const toggleSocketConnection = (status) => ({
  type: TOGGLE_SOCKET,
  payload: status
})

export const setNotes = (notes) => ({
  type: SET_NOTES,
  payload: notes
})

export const setPaymentRequestsCount = (count) => ({
  type: SET_PAY_REQUESTS_COUNT,
  payload: count
})
