import {
    combineReducers
} from 'redux'
import {
    appReducer,
} from './appReducer'
import {
    docsReducer,
} from './docsReducer'
import {
    payMethodsReducer,
} from './payMethodsReducer'
import {
    chequeReducer,
} from './chequeReducer'

export const rootReducer = combineReducers({
    appReducer,
    docsReducer,
    payMethodsReducer,
    chequeReducer,
})