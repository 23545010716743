import axios from 'axios'
import {
    showErrorNotification
} from '../redux'
import Storages from './Storages'

export default function AxiosErrorHandler(dispatch) {
    try {
        axios.interceptors.response.use(
            function (response) {
                if (response.data.status !== undefined)
                    if (!response.data.status) dispatch(showErrorNotification(response.data, true))

                return response
            },
            function (error) {
                console.log(error.response?.status)
                // try {
                    if (error.response?.status == 401) {
                        Storages.removeProfile()
                    } else {
                        try {
                            dispatch(showErrorNotification(error?.response?.data, true))
                        } catch (error) {
                            console.log(error)
                        }
                    }
                // } catch (error) {
                //     console.log(error)
                // }

                // return Promise.reject(error)
                throw error
            },
        )
    } catch (error) {
        console.log(error)
    }
}