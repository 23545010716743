import React from 'react';
import ReactDOM from 'react-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/bootsraps/bootstrap_css/bootstrap.min.css';
import './assets/styles/app.scss';
import './assets/styles/scrollbar.css';
import './assets/styles/card_primary.css';
import './assets/styles/bg_card.css';
import './assets/styles/app_table.css';
import App from './components/App';

import reportWebVitals from './reportWebVitals';


import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import configureStore from './redux/store'

const { store, persistor } = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      {/* <React.StrictMode> */}
        <App />
      {/* </React.StrictMode> */}
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
