import React, {
  useEffect,
  lazy,
  Suspense,
  useState,
  useLayoutEffect,
} from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import Loading from "./Loading";
import useEndpoints from "../api/useEndpoints";
import { countriesInfo, changeLocal, sethomePageData } from "../redux";

import AxiosErrorHandler from "../helpers/AxiosErrorHandler";
import Storages from "../helpers/Storages";

const AppRouter = lazy(() => import("./AppRouter"));

export default function App() {
  const dispatch = useDispatch();
  const appRedux = useSelector((state) => state.appReducer);
  const endpoints = useEndpoints();
  const [requested, setRequest] = useState(false);

  // useEffect(() => {
  //   sendRequest(endpoints).then((res) => {
  //     if (res !== undefined) {
  //       dispatch(countriesInfo(res))
  //       setRequest(true)
  //     }
  //   })

  //   AxiosErrorHandler(dispatch)
  // }, [])

  useEffect(() => {
    sendRequest(endpoints).then((res) => {
      if (res?.countries !== undefined) {
        dispatch(countriesInfo(res.countries));
        dispatch(sethomePageData(res.homeData));
        setRequest(true);
      }
    });

    AxiosErrorHandler(dispatch);
  }, []);

  useEffect(() => {
    getPages()
  }, [appRedux.local]);

  const getPages=()=>{
    // axios.get(endpoints.pages,{params:{PageNumber:pageNumber}}).then(res=>{

    // })
  }

  useLayoutEffect(() => {
    dispatch(changeLocal(Storages.getLang()));
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      {requested ? <AppRouter /> : <Loading />}
    </Suspense>
  );
}

// function sendRequest(endpoints) {
//   return axios
//     .get(endpoints.countris)
//     .then((res) => {
//       return new Promise((resolve) => {
//         resolve(res.data.result)
//       })
//     })
//     .catch((e) => {})
// }

function sendRequest(endpoints) {
  return axios
    .get(endpoints.countris, { params: { Type: "web" } })
    .then((res) => {
      let countries = res.data.result;
      return new Promise((resolve) => {
        getHomeData(endpoints).then((res) => {
          let data = { countries: countries, homeData: res };
          resolve(data);
        });
      });
    })
    .catch((e) => {});
}

function getHomeData(endpoints) {
  return axios.get(endpoints.info).then((res) => {
    return new Promise((resolve) => {
      resolve(res.data.result);
    });
  });
}
