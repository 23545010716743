//app
export const TOGGLE_THEME = "TOGGLE_THEME"
export const TOGGLE_DRAWER = "TOGGLE_DRAWER"
export const SMALL_SCREEN = "SMALL_SCREEN"
export const LOCAL_CHANGE = "LOCAL_CHANGE"
export const COUTRIES_INFO = "COUTRIES_INFO"
export const SET_HOME_PAGE_DATE = "SET_HOME_PAGE_DATE"
export const GET_TOTAL_COUNTRUIES = "GET_TOTAL_COUNTRUIES"
export const AXIOS_SUCCESS = "AXIOS_SUCCESS"
export const ERROR_NOTIFICATION = "ERROR_NOTIFICATION"
export const SET_TOTAL_CURRENCIES = "SET_TOTAL_CURRENCIES"
export const SET_ACCESS_LIST = "SET_ACCESS_LIST"
export const TOGGLE_SOCKET = "TOGGLE_SOCKET"
export const SET_NOTES = "SET_NOTES"
export const SET_PAY_REQUESTS_COUNT = "SET_PAY_REQUESTS_COUNT"
export const SET_PAGES = "SET_PAGES"

//docs countries
export const GET_LANGUAGES_LIST = "GET_LANGUAGES_LIST"
export const GET_COUNTRIES_LIST = "GET_COUNTRIES_LIST"
export const COUNTRIES_DOCS = "COUNTRIES_DOCS"
export const SET_COUNTRIE_ID = "SET_COUNTRIE_ID"
export const DOCS_TYPE = "DOCS_TYPE"
export const DOC_FIELD = "DOC_FIELD"
export const FIELD_TYPES = "FIELD_TYPES"
export const WAITITNG = "WAITITNG"
export const COUNTRIES_DOCS_LOADING = "COUNTRIES_DOCS_LOADING"
export const DOCS_FIELDS_LOADING = "DOCS_FIELDS_LOADING"
export const DOC_FIELD_DETAILS_LOADING = "DOC_FIELD_DETAILS_LOADING"
export const DOC_FIELD_DETAILS = "DOC_FIELD_DETAILS"
export const TOGGLE_COUNTRY_DOCS_LANG_MODAL = "TOGGLE_COUNTRY_DOCS_LANG_MODAL"

//pay methods
export const PAYMENTS_LOADING = "PAYMENTS_LOADING"
export const GET_PAYMENTS_LIST = "GET_PAYMENTS_LIST"
export const GET_PAYMENT_TYPES = "GET_PAYMENT_TYPES"
export const UPDATE_DELIVER_STATUS = "UPDATE_DELIVER_STATUS"
export const PAGINATE_PAYMENTS_LIST = "PAGINATE_PAYMENTS_LIST"


//cheque
export const SET_USERS = "SET_USERS"
export const SET_USER_ID = "SET_USER_ID"
export const SET_RECEIVERS = "SET_RECEIVERS"
export const INIT_BASE_COUNTRY = "INIT_BASE_COUNTRY"
export const TOGGLE_TEMP_RECEIVERS_WAITING = "TOGGLE_TEMP_RECEIVERS_WAITING"
export const SET_ADD_RECEIVERS_ERRORS = "SET_ADD_RECEIVERS_ERRORS"
export const CLEAR_ADD_RECEIVERS_ERRORS = "CLEAR_ADD_RECEIVERS_ERRORS"
export const SET_ADD_RECEIVERS_WAITING = "SET_ADD_RECEIVERS_WAITING"
export const SET_TEMP_RECEIVERS = "SET_TEMP_RECEIVERS"
export const SET_FORMAT_LIST = "SET_FORMAT_LIST"
export const SET_FORMAT_STRING = "SET_FORMAT_STRING"
export const SET_SUM_PAY_AMOUNT = "SET_SUM_PAY_AMOUNT"
export const SET_SUM_PAY_AMOUNT_WAITIMNG = "SET_SUM_PAY_AMOUNT_WAITIMNG"
export const SET_BANK_LIST = "SET_BANK_LIST"
export const TOGGLE_BASE_CURRENCY_LOADING = "TOGGLE_BASE_CURRENCY_LOADING"
export const SET_ORIGIN_COUNTRY = "SET_ORIGIN_COUNTRY"
export const TOGGLE_CONVERT_CURRENCY_LOADING = "TOGGLE_CONVERT_CURRENCY_LOADING"
export const SET_DEST_COUNTRY = "SET_DEST_COUNTRY"
export const SET_ORIGIN_COUNTRY_CURENCY = "SET_ORIGIN_COUNTRY_CURENCY"
export const SET_DEST_COUNTRY_CURENCY = "SET_DEST_COUNTRY_CURENCY"
export const TOGGLE_CURRENCY_RATE = "TOGGLE_CURRENCY_RATE"
export const SET_CURRENCY_RATE = "SET_CURRENCY_RATE"
export const SET_CURRENCY_RATE_MSG = "SET_CURRENCY_RATE_MSG"
export const SET_CURRENCY_RATE_EDIT = "SET_CURRENCY_RATE_EDIT"
export const HANDLE_CHEQUE_NEW_RECEIVER = "HANDLE_CHEQUE_NEW_RECEIVER"
export const HANDLE_INPUT_SELECT_CHEQUE = "HANDLE_INPUT_SELECT_CHEQUE"
export const TOGGLE_ADD_NEW_RECEIVER = "TOGGLE_ADD_NEW_RECEIVER"
export const CALC_AMOUNT_RECEIVE = "CALC_AMOUNT_RECEIVE"
export const SET_AMOUNT_RECEIVE = "SET_AMOUNT_RECEIVE"
export const SET_AMOUNT_SENT = "SET_AMOUNT_SENT"
export const CALC_AMOUNT_SENT = "CALC_AMOUNT_SENT"
export const CLEAR_CHEQUE_STORE = "CLEAR_CHEQUE_STORE"
export const ADD_RECEIVER = "ADD_RECEIVER"
export const ADD_TO_RECEIVERS = "ADD_TO_RECEIVERS"
export const SET_PAY_TYPES = "SET_PAY_TYPES"
export const SET_PAY_TYPE_ID = "SET_PAY_TYPE_ID"
export const SET_RECEIVE_TYPES = "SET_RECEIVE_TYPES"
export const SET_TEMP_TRANSFER_ID = "SET_TEMP_TRANSFER_ID"
export const TOGGLE_SEVE_INFO_WAITING = "TOGGLE_SEVE_INFO_WAITING"
export const CHEQUE_ADDED = "CHEQUE_ADDED"
export const TOGGLE_ADD_NEW_DEPOSIT_MODAL = "TOGGLE_ADD_NEW_DEPOSIT_MODAL"
export const SET_TRANSFER_ID = "SET_TRANSFER_ID"