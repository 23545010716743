import axios from 'axios'
import Storages from '../../helpers/Storages'
import {
    PAYMENTS_LOADING,
    GET_PAYMENTS_LIST,
    GET_PAYMENT_TYPES,
    UPDATE_DELIVER_STATUS,
    PAGINATE_PAYMENTS_LIST,
} from '../actionTypes'

export const togglePaymentsLoading = (status) => ({
    type: PAYMENTS_LOADING,
    payload: status
})

export const getPaymentsList = (endpoints, countryId, PageNumber) => {
    return (dispatch) => {
        dispatch(togglePaymentsLoading(true))
        axios.get(endpoints, {
                params: {
                    CountryId: countryId ?? '',
                    PageNumber: PageNumber ?? ''
                },
                headers: {
                    'x-auth-token': Storages.getUserToken()
                }
            }).then(res => {
                dispatch(togglePaymentsLoading(false))
                if(res.data.status) dispatch(setPaymentsList(res.data.result))
            })
            .catch(e => {
                console.log(e)
            })
    }
}


export const setPaymentsList = (list) => ({
    type: GET_PAYMENTS_LIST,
    payload: list
})

export const getPaymentTypes = (endpoints) => {
    return (dispatch) => {
        axios.get(endpoints, {
                headers: {
                    'x-auth-token': Storages.getUserToken()
                }
            }).then(res => {
                if(res.data.status) dispatch(setPaymentTypes(res.data.result))
            })
            .catch(e => {
                console.log(e)
            })
    }
}

export const setPaymentTypes = (list) => ({
    type: GET_PAYMENT_TYPES,
    payload: list
})

export const updateDeliverStatus = (list) => ({
    type: UPDATE_DELIVER_STATUS,
    payload: list
})

export const paginatePaymentList = (pageNumber) => ({
    type: PAGINATE_PAYMENTS_LIST,
    payload: pageNumber
})